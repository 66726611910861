<template>
	<div class="settings-view">
		<h2 class="heading">Settings</h2>
		<div class="settings">
			<section>
				<h2 class="section-heading">Theme</h2>
				<div class="toggle theme-toggle">
					<div class="default" @click="setTheme('default');">
						Standard
						<icon
							class="active-icon"
							width="20"
							color="#3db4f2"
							:fill="true"
							name="check"
							:class="{ visible: theme === 'default' }"
						/>
					</div>
					<div class="contrast" @click="setTheme('contrast');">
						High Contrast
						<icon
							class="active-icon"
							width="20"
							color="#3db4f2"
							:fill="true"
							name="check"
							:class="{ visible: theme === 'contrast' }"
						/>
					</div>
					<div class="dark" @click="setTheme('dark');">
						Night
						<icon
							class="active-icon"
							width="20"
							color="#3db4f2"
							:fill="true"
							name="check"
							:class="{ visible: theme === 'dark' }"
						/>
					</div>
				</div>
			</section>

			<section>
				<h2 class="section-heading">Title Language</h2>
				<div class="toggle">
					<div @click="setTitleLangauge('romaji');">
						Romaji
						<icon
							class="active-icon"
							width="20"
							color="#3db4f2"
							:fill="true"
							name="check"
							:class="{ visible: language === 'romaji' }"
						/>
					</div>
					<div @click="setTitleLangauge('english');">
						English
						<icon
							class="active-icon"
							width="20"
							color="#3db4f2"
							:fill="true"
							name="check"
							:class="{ visible: language === 'english' }"
						/>
					</div>
					<div @click="setTitleLangauge('native');">
						Native
						<icon
							class="active-icon"
							width="20"
							color="#3db4f2"
							:fill="true"
							name="check"
							:class="{ visible: language === 'native' }"
						/>
					</div>
				</div>
			</section>

			<section>
				<h2 class="section-heading">Outgoing Anime Links</h2>
				<div class="toggle">
					<div @click="setOutgoingLinkProvider('anilist');">
						AniList.co
						<icon
							class="active-icon"
							width="20"
							color="#3db4f2"
							:fill="true"
							name="check"
							:class="{ visible: site === 'anilist' }"
						/>
					</div>
					<div @click="setOutgoingLinkProvider('mal');">
						MyAnimeList.net
						<icon
							class="active-icon"
							width="20"
							color="#3db4f2"
							:fill="true"
							name="check"
							:class="{ visible: site === 'mal' }"
						/>
					</div>
				</div>
			</section>

			<section>
				<h2 class="section-heading">Hide Anime I'm Not Watching</h2>
				<div class="toggle">
					<div @click="setHideNotWatching(true);">
						Enabled
						<icon
							class="active-icon"
							width="20"
							color="#3db4f2"
							:fill="true"
							name="check"
							:class="{ visible: hideNotWatching }"
						/>
					</div>
					<div @click="setHideNotWatching(false);">
						Disabled
						<icon
							class="active-icon"
							width="20"
							color="#3db4f2"
							:fill="true"
							name="check"
							:class="{ visible: !hideNotWatching }"
						/>
					</div>
				</div>
			</section>
		</div>

		<h2 class="heading">Login via AniList</h2>
		<div class="settings" v-if="!authenticatedUser.id">
			<section class="features">
				<h2 class="section-heading">Login via AniList to enable the following features:</h2>
				<div class="feature">
					<icon width="24" color="#5dc12f" :fill="true" name="check" />
					<span>Sync AniChart settings & highlights across devices</span>
				</div>
				<div class="feature">
					<icon width="24" color="#5dc12f" :fill="true" name="check" />
					<span>Update your AniList anime list from AniChart</span>
				</div>
				<div class="feature">
					<icon width="24" color="#5dc12f" :fill="true" name="check" />
					<span>Remove ads for AniList donators</span>
				</div>
			</section>

			<section class="login-wrap">
				<a
					class="login"
					href="https://anilist.co/api/v2/oauth/authorize?client_id=1562&response_type=token"
					>Login</a
				>
			</section>
		</div>
		<div class="settings" v-else>
			<h2 class="section-heading">Logged in as {{ authenticatedUser.name }}</h2>
			<section class="login-wrap"><a class="login" @click="logout">Logout</a></section>
		</div>
	</div>
</template>

<script>
import Settings from "@/util/settings";
import Auth from "@/util/auth";

export default {
	data() {
		return {
			theme: Settings.theme,
			language: Settings.titleLanguage,
			site: Settings.outgoingLinkProvider,
			hideNotWatching: Settings.hideNotWatching
		};
	},
	computed: {
		authenticatedUser() {
			return this.$store.state.auth.user;
		}
	},
	methods: {
		setTheme(theme) {
			this.theme = theme;
			Settings.theme = theme;
		},
		setTitleLangauge(language) {
			this.language = language;
			Settings.titleLanguage = language;
		},
		setOutgoingLinkProvider(site) {
			this.site = site;
			Settings.outgoingLinkProvider = site;
		},
		setHideNotWatching(hidden) {
			this.hideNotWatching = hidden;
			Settings.hideNotWatching = hidden;
		},
		logout() {
			Auth.logout();
		}
	},
	mounted() {
		document.body.classList.add("settings-transition");
	},
	beforeRouteLeave(to, from, next) {
		document.body.classList.remove("settings-transition");
		next();
	},
	metaInfo() {
		return {
			title: "Settings"
		};
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.settings-view {
	margin: 0 auto;
	position: relative;
	max-width: 660px;
}

.heading {
	display: flex;
	font-size: 2.4rem;
	font-weight: 700;
	letter-spacing: 0.1rem;
	margin-bottom: 15px;
	margin-top: 10px;

	&:first-of-type {
		color: #fff;
	}

	@media (max-width: $size-tablet) {
		color: color(text);
		font-size: 1.5rem;
		font-weight: 900;
		letter-spacing: 0.1rem;
		padding-left: 20px;
		padding-top: 10px;
		text-align: left;
		text-transform: uppercase;

		&:first-of-type {
			color: color(text);
		}
	}
}

.section-heading {
	font-size: 1.6rem;
	font-weight: 600;
	margin: 0;
	margin-bottom: 14px;
}

.settings {
	background: color(foreground);
	border-radius: 3px;
	margin-bottom: 50px;
	overflow: hidden;
	padding: 40px;
	padding-bottom: 0;

	> section {
		margin-bottom: 55px;
	}

	@media (max-width: $size-tablet) {
		padding: 20px;
	}
}

section.features {
	margin-bottom: 35px;

	.section-heading {
		margin-bottom: 30px;
	}
}

.feature {
	align-items: center;
	display: flex;
	font-size: 1.8rem;
	font-weight: 700;
	margin-bottom: 14px;

	span {
		padding-left: 10px;
	}
}

section.login-wrap {
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
}

.login {
	background: color(blue);
	cursor: pointer;
	font-weight: 800;
	padding: 10px 26px;
	font-size: 1.7rem;
	color: color(white);
	border-radius: 3px;

	&:hover {
		color: color(white);
	}
}

.toggle {
	border-radius: 4px;
	display: grid;
	grid-gap: 20px;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

	.active-icon {
		position: absolute;
		margin-top: 3px;
		right: 12px;
		opacity: 0;
		transition: opacity 0.2s ease;

		&.visible {
			animation: bounceIn 0.65s;
			opacity: 1;
		}
	}

	@media (max-width: $size-tablet) {
		grid-gap: 0;
		grid-template-columns: 1fr;
		overflow: hidden;
	}
}

.toggle div {
	background: color(background);
	border-radius: 5px;
	border: solid 2px color(text, 0.1);
	color: color(text);
	cursor: pointer;
	display: flex;
	font-size: 1.5rem;
	font-weight: 700;
	padding: 14px 18px;
	position: relative;

	@media (max-width: $size-tablet) {
		border: none;
		border-radius: 0;
	}
}

.theme-toggle > div {
	&.default {
		background: #edf1f5;
		color: #5c728a;
	}

	&.dark {
		background: #13171d;
		color: #9fadbd;
	}

	&.contrast {
		background: #edf1f5;
		color: #000;
	}
}
</style>

<style lang="scss">
.settings-transition {
	transition: color 0.6s ease-in-out, background 0.6s ease;
}
</style>
