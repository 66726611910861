<template>
	<div class="auth-view">
		<h2 class="heading">User Setup</h2>
		<div class="auth">
			<template v-if="!authenticatedUser.id">
				Logging in...
			</template>
			<template v-else>
				<section>
					<h2 class="section-heading">Select one of the following options to complete login</h2>
					<div class="toggle">
						<div @click="syncServer">
							<h3 class="title">I have never synced with any device before</h3>
							<span class="details">Sync my local settings & highlights to the cloud</span>
						</div>
						<div @click="syncClient">
							<h3 class="title">I have already synced on another device</h3>
							<span class="details"
								>Replace my local settings & highlights with those from a previous cloud sync</span
							>
						</div>
					</div>
				</section>
			</template>
		</div>
	</div>
</template>

<script>
import Auth from "@/util/auth";
import Settings from "@/util/settings";

export default {
	data() {
		return {
			synced: false
		};
	},
	computed: {
		authenticatedUser() {
			return this.$store.state.auth.user;
		}
	},
	methods: {
		async getAccessToken() {
			const queryHash = this.$route.hash;
			const accessToken = queryHash.substring(
				queryHash.indexOf("#access_token=") + 14,
				queryHash.lastIndexOf("&token")
			);

			if (!accessToken) {
				return this.loginFailed();
			}

			const success = await Auth.login(accessToken);
			if (!success) {
				return this.loginFailed();
			}
		},
		async syncClient() {
			this.synced = true;
			await Auth.syncClient();
			document.body.classList.add(`site-theme-${Settings.theme}`);
			this.$router.replace({
				path: "/"
			});
		},
		async syncServer() {
			this.synced = true;
			await Auth.syncServer();
			this.$router.replace({
				path: "/"
			});
		},
		loginFailed() {
			this.$store.dispatch("notify", {
				header: "Login failed",
				type: "error"
			});
			this.$router.replace({
				path: "/settings"
			});
		}
	},
	created() {
		this.getAccessToken();
	},
	beforeRouteLeave(to, from, next) {
		if (!this.synced) {
			Auth.logout();
		}
		next();
	},
	metaInfo() {
		return {
			title: "AniChart Login"
		};
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.auth-view {
	margin: 0 auto;
	position: relative;
	max-width: 1060px;
}

.heading {
	display: flex;
	font-size: 2.4rem;
	font-weight: 700;
	letter-spacing: 0.1rem;
	margin-bottom: 15px;
	margin-top: 10px;

	&:first-of-type {
		color: #fff;
	}

	@media (max-width: $size-tablet) {
		color: color(text);
		font-size: 1.5rem;
		font-weight: 900;
		letter-spacing: 0.1rem;
		padding-left: 20px;
		padding-top: 10px;
		text-align: left;
		text-transform: uppercase;

		&:first-of-type {
			color: color(text);
		}
	}
}

.section-heading {
	font-size: 1.8rem;
	font-weight: 600;
	margin: 0;
	margin-bottom: 30px;
	text-align: center;
}

.auth {
	background: color(foreground);
	border-radius: 3px;
	margin-bottom: 50px;
	overflow: hidden;
	padding: 40px;

	@media (max-width: $size-tablet) {
		padding: 20px;
	}
}

.toggle {
	border-radius: 4px;
	display: grid;
	grid-gap: 30px;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

	.title {
		font-size: 1.8rem;
		margin: 0;
		margin-bottom: 20px;
	}

	.details {
		font-weight: 500;
		font-size: 1.4rem;
	}

	@media (max-width: $size-tablet) {
		grid-gap: 0;
		grid-template-columns: 1fr;
		overflow: hidden;
	}
}

.toggle > div {
	background: color(background);
	border-radius: 5px;
	border: solid 2px color(text, 0.1);
	color: color(text);
	cursor: pointer;
	display: flex;
	flex-direction: column;
	font-size: 1.5rem;
	font-weight: 700;
	padding: 24px;
	position: relative;
	text-align: center;

	@media (max-width: $size-tablet) {
		border: none;
		border-radius: 0;
	}
}
</style>
